import React, { useState, useEffect } from "react";
import HeartRateImage from "../../assets/icons/heart-rate.svg";
import HeartRate from "../../assets/icons/heart-rate-2.svg";
import Temp from "../../assets/icons/temp.svg";
import BloodPressure from "../../assets/icons/blood-pressure.svg";
import BloodAnalysis from "../../assets/icons/body-analysis.svg";
import Weight from "../../assets/icons/weight.svg";
import Spo2Icon from "../../assets/icons/spo2Icon.svg";
import spo2Image from "../../assets/icons/spo2.svg";
import DefaultBodyCompositionImage from "../../assets/default-body-composition-image.png";
import BodyCompositionImage1 from "../../assets/body-composition-image1.png";
import BodyCompositionImage2 from "../../assets/body-composition-image2.png";
import BodyCompositionImage3 from "../../assets/body-composition-image3.png";
import HeightIcon from "../../assets/icons/height.svg";
import angleDown from "../../assets/icons/downarrow.svg";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTests, addReferedBy } from "../../Redux_Services/slices/testSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function BodyComposition() {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.testSlice.testsData || []);
  const [testData, setTestData] = useState(tests);
  const patientDetails = useSelector((state) => state.patientSlice.patientData);
  const [patientData, setPatientData] = useState(patientDetails);
  console.log(patientDetails);
  useEffect(()=>{
    calibrateWeight("prepare_weight");
  },[])
  const arrayImages = [
    DefaultBodyCompositionImage,
    BodyCompositionImage1,
    BodyCompositionImage2,
    BodyCompositionImage3,
  ];
  const [currentImage, setCurrentImage] = useState(BodyCompositionImage1);
  const [intervalId, setIntervalId] = useState(null); // To track the interval ID
  const [isOpen, setIsOpen] = useState(false);
  const [dropValue, setDropValue] = useState("Body Type");
  const [res, setres] = useState({});
  console.log(res);

  const {
    heartRate = "Heart Rate",
    temperature = "Temperature",
    bloodPressure = "Blood Pressure",
    height = "Height",
    weight = "Weight",
    bmi = "BMI",
  } = res;
  const impe = "Impedence Data";
  let counter = 0;

  const [form, setForm] = useState(" ");
  const [data, setData] = useState(null);
  // const [count, setCount] = useState(0);
  const [impedanceData, setImpedanceData] = useState({
    body_oxygen_saturation: null,
    visceral_fat: null,
    body_fat: null,
    skeletal_muscle: null,
    body_age: null,
    resting_metabolism: null,
  });

  const navigate = useNavigate();
  const handleDropdownSelect = (value) => {
    setDropValue(value);
  };

  const calibrateWeight = async (task_id) => {
      try {
        const { data } = await axios.post(
          "http://localhost:5500/perform_task",
          {
            task_id,
            name: patientDetails.Name,
            age: patientDetails.Age,
            user_id: patientDetails.PatientID,
            body_type: "Average",
            gender:"Male"
          }
        );

        console.log("Response from server:", data);
        const dataKey = Object.keys(data)[1];

        console.log("secondIndex", dataKey);
        if (data.success === "true") {
          toast.success(data, {
            position: "top-center", // Position in the middle of the screen
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false, // Show progress bar
            closeOnClick: true, // Close on click
            pauseOnHover: true, // Pause on hover
            draggable: true, // Allow dragging to dismiss
          });
        } else {
          toast.error("Error fetching data from server", {
            position: "top-center", // Position in the middle of the screen
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false, // Show progress bar
            closeOnClick: true, // Close on click
            pauseOnHover: true, // Pause on hover
            draggable: true, // Allow dragging to dismiss
          });// Show error message if body type is not selected
        }
      } catch (error) {
        toast.error(`Error fetching data from server:, ${error}`, {
          position: "top-center", // Position in the middle of the screen
          autoClose: 3000, // Auto close after 3 seconds
          hideProgressBar: false, // Show progress bar
          closeOnClick: true, // Close on click
          pauseOnHover: true, // Pause on hover
          draggable: true, // Allow dragging to dismiss
        });// Show error message if body type is not selected
        console.error("Error fetching data from server:", error);
      }
  };

  const fetchDataFromServer = async (task_id) => {
    console.log("_____________",{task_id,
      name: patientDetails.Name,
      age: patientDetails.Age,
      userid: patientDetails.PatientID,
      bodyType: dropValue,});
    if (dropValue !== 'Body Type') {
      try {
        const { data } = await axios.post(
          "http://localhost:5500/perform_task",
          {
            task_id,
            name: patientDetails.Name,
            age: patientDetails.Age,
            user_id: patientDetails.PatientID,
            body_type: dropValue,

            gender:"Male"
          }
        );

        console.log("Response from server:", data);
        const dataKey = Object.keys(data)[1];

        console.log("secondIndex", dataKey);
        if (data.success === "true") {
          toast.success(data, {
            position: "top-center", // Position in the middle of the screen
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false, // Show progress bar
            closeOnClick: true, // Close on click
            pauseOnHover: true, // Pause on hover
            draggable: true, // Allow dragging to dismiss
          });
          setres((prevRes) => ({ ...prevRes, ...data }));
        } else {
          toast.error("Error fetching data from server", {
            position: "top-center", // Position in the middle of the screen
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false, // Show progress bar
            closeOnClick: true, // Close on click
            pauseOnHover: true, // Pause on hover
            draggable: true, // Allow dragging to dismiss
          });// Show error message if body type is not selected
        }
      } catch (error) {
        toast.error(`Error fetching data from server:, ${error}`, {
          position: "top-center", // Position in the middle of the screen
          autoClose: 3000, // Auto close after 3 seconds
          hideProgressBar: false, // Show progress bar
          closeOnClick: true, // Close on click
          pauseOnHover: true, // Pause on hover
          draggable: true, // Allow dragging to dismiss
        });// Show error message if body type is not selected
        console.error("Error fetching data from server:", error);
      }
    }
    else {
      toast.error("Please select a body type.", {
        position: "top-center", // Position in the middle of the screen
        autoClose: 3000, // Auto close after 3 seconds
        hideProgressBar: false, // Show progress bar
        closeOnClick: true, // Close on click
        pauseOnHover: true, // Pause on hover
        draggable: true, // Allow dragging to dismiss
      });// Show error message if body type is not selected
    }
  };

  const fetchDataForImpedence = async (test_id) => {
    if (dropValue) {
      try {
        // Here you can call getTaskData directly
        const { data } = await axios.post(
          "http://localhost:5500/perform_task",
          {
            test_id,
            name: patientDetails.Name,
            age: patientDetails.Age,
            userid: patientDetails.PatientID,
            bodyType: dropValue,
          }
        );
        
        // Check if data is successful before setting state
        if ( data.success === 'true') {
          setImpedanceData(data);
        } else {
          console.error("Failed to fetch impedance data.");
        }
      } catch (error) {
        console.error("Error fetching impedance data:", error);
      }
    }
  };

  console.log('impedence ' , impedanceData)
  // Log the updated `res` state whenever it changes
  useEffect(() => {
    console.log("Updated res:", res); // This should log the updated state after merging
  }, [res]);

  const defaultImpedanceData = {
    body_oxygen_saturation: null,
    visceral_fat: null,
    body_fat: null,
    skeletal_muscle: null,
    body_age: null,
    resting_metabolism: null,
  };

  function handleReset() {
    setImpedanceData(defaultImpedanceData )
    setres({
      heartRate: "Heart Rate",
      temperature: "Temperature",
      bloodPressure: " Blood Pressure",
      height: "Height",
      weight: "Weight",
      bmi: "BMI",
      
    });
  }

  function handleHeart() {
    handleAnimation();
    setForm("measure_heart_rate");
    fetchDataFromServer("measure_heart_rate");
  }

  function handleTemperature() {
    handleAnimation();
    setForm("measure_temperature");
    fetchDataFromServer("measure_temperature");
  }

  function handleBloodPressure() {
    handleAnimation();
    setForm("measure_bloodPressure");
    fetchDataFromServer("measure_bloodPressure");
  }

  function handleHeight() {
    handleAnimation();
    setForm("measure_height");
    fetchDataFromServer("measure_height");
  }

  function handleWeight() {
    handleAnimation();
    setForm("measure_weight");
    fetchDataFromServer("measure_weight");
  }

  function handleBodyAnalysis() {
    handleAnimation();
    setForm("measure_impedance");
    fetchDataFromServer('measure_impedance');
    //fetchDataForImpedence('measure_impedence');
  }

  function handleSpo2() {
    handleAnimation();
    setForm("measure_bmi");
    fetchDataFromServer("measure_bmi");
  }

  function handleAnimation() {
    let index = 1;

    if (intervalId) {
      clearInterval(intervalId);
    }

    const newIntervalId = setInterval(() => {
      setCurrentImage(arrayImages[index]);
      index++;

      if (index === arrayImages.length) {
        clearInterval(newIntervalId);
        setTimeout(() => {
          setCurrentImage(DefaultBodyCompositionImage);
        }, 2000);
      }
    }, 2000);

    setIntervalId(newIntervalId);
  }

  const handleSave = () => {
    navigate("/tests/testId/report");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-[07%] font-popins">
      <div className="w-full max-w-[80rem] mx-auto">
      <h1 className="text-3xl mt-[-3%] px-10 font-extrabold text-[#845BB3]">
          BODY COMPOSITION
        </h1>

        <div className="flex mb-[6%] items-start w-full justify-end  space-x-[10%] ">
          <div className="p-4 m-10 w-full max-w-fit flex relative">
            <div className="flex flex-col gap-8 justify-center cursor-pointer absolute left-0">
              <div onClick={handleHeart} className="flex flex-col items-center">
                <img className="w-14 h-14" src={HeartRate} alt="Heart Rate" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  {heartRate}
                </p>
              </div>

              <div
                onClick={handleTemperature}
                className="flex flex-col items-center mr-19 mt-6"
              >
                <img className="w-14 h-14" src={Temp} alt="Temperature" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  {temperature}
                </p>
              </div>

              <div
                onClick={handleBloodPressure}
                className="flex flex-col items-center"
              >
                <img
                  className="w-14 h-14"
                  src={BloodPressure}
                  alt="Blood Pressure"
                />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  {bloodPressure}
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center gap-2">
              <img
                className="w-[30rem] h-[30rem] object-contain mx-auto"
                src={currentImage}
                alt="Current Body Composition"
              />

              <div className="flex flex-col items-center mt-6">
                <div
                  onClick={handleBodyAnalysis}
                  className="flex flex-col items-center"
                >
                  <img
                    className="w-14 h-14"
                    src={BloodAnalysis}
                    alt="Body Analysis"
                  />
                </div>
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  {impe}
                </p>
                
                <div className="flex flex-col items-center">
                  <button
                    onClick={handleReset}
                    className="text-red-500 text-sm px-2 py-1 rounded-md border border-red-500 w-fit mt-2"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div className="flex flex-col gap-8 justify-center absolute right-0">
                <button
                  onClick={handleHeight}
                  className="flex flex-col items-center"
                >
                  <img className="w-14 h-14" src={HeightIcon} alt="Height" />
                  <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                    {height}
                  </p>
                </button>

                <button
                  onClick={handleWeight}
                  className="flex flex-col items-center ml-16"
                >
                  <img className="w-14 h-14" src={Weight} alt="Weight" />
                  <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                    {weight}
                  </p>
                </button>

                <button
                  onClick={handleSpo2}
                  className="flex flex-col items-center mt-10 ml-4"
                >
                  <img className="w-14 h-14" src={Spo2Icon} alt="SpO2" />
                  <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                    {bmi}
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full ">
            <div className="w-96 mt-10">
              <div
                onClick={toggleDropdown}
                className="flex items-center justify-between border border-[#845BB3] rounded-md cursor-pointer px-2 py-1"
              >
                <p className="text-[#845BB3] text-sm">{dropValue}</p>
                <img className="w-6 h-6" src={angleDown} alt="Dropdown Icon" />
              </div>

              {isOpen && (
                <div className="mt-2 border border-[#845BB3] rounded-md bg-[#845BB3]shadow-md ">
                  {["athelete", "Average", "Fat"].map((option) => (
                    <div
                      key={option}
                      onClick={() => handleDropdownSelect(option)}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="mt-10 w-full">
              <div className="text-2xl text-purple-800">
                <h2 className="font-stretch text-3xl font-bold ">
                  Impedence Data
                </h2>
              </div>
              <div className="flex flex-row gap-x-[15%] w-full  ">
                <div className="flex flex-col w-[50%]">
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Skeletal Muscle</h4>
                    <p className="text-purple-800 text-sm px-2 h-9  flex items-center justify-center   rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.skeletal_muscle}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Resting Metabolism</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.resting_metabolism}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Body Age</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.body_age}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col w-[50%]">
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Body Oxygen Saturation</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.body_oxygen_saturation}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Visceral Fat</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.visceral_fat}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-[#845BB3] text-lg">Body Fat</h4>
                    <p className="text-purple-800 text-sm px-2 h-9 flex items-center justify-center text-bold rounded-md border border-[#845BB3] w-[50%] mt-2">
                      {impedanceData.body_fat}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[10%]">
              <button
                onClick={handleSave}
                className="bg-[#AB5C5C] rounded-md text-white text-base font-semibold py-2 px-2 flex items-center"
              >
                <span className="mr-2">GENERATE REPORT</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
