import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import help from "../../assets/help.svg";
import sidevector from "../../assets/sidevector.svg";
import download from "../../assets/downloadpdf.svg";
import share from "../../assets/sharepdf.svg";
import PatientReport from "../../Components/PatientReport";
import ReactToPrint from "react-to-print";
import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";


export default function ReportPage() {
  const location = useLocation();
  const patientDetails = useSelector(state => state.patientSlice.patientData);
  const testData = useSelector(state => state.testSlice.testsData);


  const newTestData = []
  // Check if testData.fields is an array before accessing length
  for (let i = 0; i < testData.length; i++) {
    const item = testData[i];
    const fieldsSize = item.fields?.length ?? 0;

    if (fieldsSize > 10) {
      // Split the fields array into two halves
      const halfSize = Math.ceil(fieldsSize / 2);
      const firstHalf = item.fields.slice(0, halfSize);
      const secondHalf = item.fields.slice(halfSize);

      // Create two new objects with split fields
      const firstPart = { ...item, fields: firstHalf };
      const secondPart = { ...item, fields: secondHalf };

      // Add the new objects to the newTestData array
      newTestData.push(firstPart, secondPart);
    } else {
      // If fields size is not greater than 10, just add the item as is
      newTestData.push(item);
    }
  }

  // Output the newTestData array
  console.log('Updated testData:', newTestData);


  // Ref to the specific part (Patient Report) you want to print
  const patientReportRef = useRef();

  // Handle Share functionality (only share the report section as PDF)
  const handleShare = () => {
    const element = patientReportRef.current;

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 4,
      },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .outputPdf("blob") // Generate PDF as Blob
      .then((pdfBlob) => {
        // Create a File object from the Blob
        const filesArray = [
          new File([pdfBlob], "report.pdf", {
            type: "application/pdf",
            lastModified: new Date().getTime(),
          }),
        ];

        // Check if the browser supports sharing files
        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator
            .share({
              files: filesArray,
              title: "Patient Report",
              text: "Here is the patient report.",
            })
            .then(() => console.log("Share was successful."))
            .catch((error) => console.log("Sharing failed", error));
        } else {
          alert("Your device does not support sharing files.");
        }
      })
      .catch((error) => console.log("Error generating PDF", error));
  };



  // // If testData is passed dynamically, group by the test field name
  // const groupedTestData = Object.values(
  //   Object.groupBy(testData, ({ testFieldName }) => testFieldName)
  // );

  // console.log("groupdata", groupedTestData);

  return (
    <>
      {/* Printable content: PatientReport components */}
      <div ref={patientReportRef}>
        {newTestData?.map((item, index) => (
          <div
            key={index}
            className="bg-[#C7CEE5] justify-center items-center flex min-h-fit py-10 px-6 z-10 relative"
            style={{ breakAfter: "page" }}
          >
            {/* Report Page PDF Viewer */}
            <div className="bg-white z-10 printable relative h-fit w-[1000px] mb-1 mx-auto">
              <PatientReport
                patientDetails={patientDetails}
                testResults={item} // Passing dynamic test results
              />
            </div>
            {/* Non-printable content: Buttons and Help Icon */}
            <div className=" fixed bottom-8 right-8 lg:right-20 ">
              {/* Download button */}
              <ReactToPrint
                trigger={() => (
                  <img
                    src={download}
                    alt="Share PDF"
                    className="w-10 lg:w-12 cursor-pointer mb-4"
                  />
                )}
                content={() => patientReportRef.current}
                documentTitle="Patient Report"
              />
              <img
                src={share}
                alt="Download PDF"
                className="w-10 lg:w-12 cursor-pointer mb-4"
                onClick={handleShare}
              />
              {/* Share button */}

              {/* Help Icon */}
              <Link to="../faq">
                <img src={help} alt="helpicon" className="w-10 lg:w-12" />
              </Link>
            </div>
          </div>
        ))}
      </div>

      {/* CSS Styles */}
      <style jsx>{`
        @media print {
          body * {
            visibility: hidden;
          }
          .printable,
          .printable * {
            visibility: visible;
          }
          .printable {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `}</style>
    </>
  );
}
